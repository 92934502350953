.main-container{
    min-height: 100vh;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: Segoe UI,sans-serif!important;
    padding: 36px;
    .logo{
        max-width: 300px;
        img{
            width: 100%;
        }
    }
    @media screen and (max-width: 768px) {
        justify-content: flex-start;
        .logo{
            max-width: 180px;
        }
    }
}
.form-container{
    max-width: 812px;
    width: 100%;
    margin-top: 82px;
    @media screen and (max-width: 768px) {
        margin: 0;
        margin-top: 62px;
    }
    form{
        width: 100%;
        .row{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            margin-bottom: 27px;
            @media screen and (max-width: 768px) {
                flex-direction: column;
                margin-bottom: 0;
                .input{
                    margin-bottom: 27px;
                }
            }
            .input{
                width: 100%;
                display: flex;
                flex-direction: column;
                position: relative;
                label{
                    font-size: 20px;
                    line-height: 24px;
                    margin-bottom: 10px;
                    @media screen and (max-width: 768px) {
                        font-size: 16px;
                        line-height: 19px;
                    }
                }
                input{
                    border: 1px solid #EAEAEA;
                    font-size: 20px;
                    height: 50px;
                    line-height: 24px;
                    padding-left: 24px;
                    color: #999999;
                    @media screen and (max-width: 768px) {
                        font-size: 16px;
                        line-height: 19px;
                    }
                }
            }
            &.dual-input{
                .input{
                    width: calc(100%/2 - 8px);
                    @media screen and (max-width: 768px) {
                        width: 100%;
                    }
                }
            }
        }
        button{
            display: flex;
            align-items: center;
            justify-content: center;
            color: black;
            text-decoration: none;
            margin: auto;
            margin-top: 48px;
            height: 52px;
            max-width: 414px;
            width: 100%;
            font-size: 20px;
            line-height: 24px;
            text-transform: uppercase;
            background-color: #FFED00;
            border: none;
            cursor: pointer;
            @media screen and (max-width: 768px) {
                font-size: 16px;
                line-height: 19px;
            }
            &:hover{
                background-color: #999999;
                color: white;
            }
        }
    }
}